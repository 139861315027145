import React from 'react'
import { graphql } from 'gatsby'
import { ArticleLayout } from "../layouts/"

export const query = graphql`
    query NotFoundQueryQuery {
        model: sanityPage(_id: { regex: "/(drafts.|)404/" }) {
            ...PageTemplate
        }
    }
`


const NotFoundPage = (props) => {

    return (
        <ArticleLayout {...props} />
    )
    
}

export default NotFoundPage